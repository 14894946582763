import { gql } from '@apollo/client';
export const UPDATE_DEPARTMENT= gql`
mutation(
   $input:DepartmentsUpdateInput!
  ){
    updateDepartmentsDetails(input: $input) {

    _id
    name
    slug
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
  }`;
