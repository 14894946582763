import React, { useEffect, useState } from "react";
import { useUrlSearchParams } from "use-url-search-params";
import { Form } from "react-bootstrap";
import { Badge } from "reactstrap";
import CsLineIcons from "../../../assets/cs-line-icons/CsLineIcons";
import { Link } from "react-router-dom";
import Pagination from "@vlsergey/react-bootstrap-pagination";
import { SORTING_CATEGORY_LIST } from "../../../constants";
import { useParams } from "react-router-dom";
import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
import moment from "moment";
import { GET_ARTICLES_DATA_LIST } from "../../query/GetArticlesList";
import { GET_CATEGORIES_NAME_LIST } from "../../query/GetCatList";


import like from "../../../assets/images/like.svg";
import unlike from "../../../assets/images/unlik.svg";
import Select from "react-select";

import {
  Table,
  Row,
  Col,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Spinner,
  FormGroup
} from "reactstrap";
const KBArticleList = () => {
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [getCatNameList, setGetCatNameList] = useState("");
  const [sortingName, setSortingName] = useState({ id: "sort.date_asc" });
  const [paginationValue, setPaginationValue] = useUrlSearchParams({
    page: "0",
  });
  const [catValue, setCatValue] = useUrlSearchParams({
    cat: "",
  });
  const [artTypeValue, setartTypeValue] = useUrlSearchParams({
    artType: "",
  });
  const [limitValue, setLimitValue] = useUrlSearchParams({ limit: 10 });
  const [sortingValue, setSortingValue] = useUrlSearchParams({
    sort: "_date",
    customer: SORTING_CATEGORY_LIST.ASC,
  });
  const [selectedGroup, setselectedGroup] = useState(null);
  const[articleType, setArticleType] = useState('');

  const [isDataLoading, setDataLoading] = useState(false);
  
  const [kbArtData, setKbArtData] = useState("");
  const [searchTrigger, setSearchTrigger] = useState("");
  const [searchValue, setSearchValue] = useUrlSearchParams({ search: "" });
  const [categoryValue, setCategoryValue] = useState("");
  const limitByValues = [5, 10, 20, 50];
  const sortByValues = [
    {
      key: "date",
      customer: SORTING_CATEGORY_LIST.ASC,
      label: { id: "sort.date_asc" },
    },
    {
      key: "date",
      customer: SORTING_CATEGORY_LIST.DESC,
      label: { id: "sort.date_desc" },
    },
    {
      key: "status",
      customer: SORTING_CATEGORY_LIST.ASC,
      label: { id: "sort.status_asc" },
    },
    {
      key: "status",
      customer: SORTING_CATEGORY_LIST.DESC,
      label: { id: "sort.status_desc" },
    },
  ];

  const handlePagination = (e) => {
    setPaginationValue({ page: e.target.value });
  };
  const handleArticleType = e => {
    setArticleType(e.target.value);
  
  
  };
  const clearFilter = () => {
    setCatValue({ cat: "" });
    setselectedGroup(null);
    setArticleType('')
    setartTypeValue({artType:""})

  };
  const handleLimit = (e) => {
    setLimitValue({ limit: Number(e) });
  };
  const handleSorting = (type, customer = SORTING_CATEGORY_LIST.ASC, name) => {
    setSortingValue({ sort: type, customer });
    setSortingName(name);
  };
  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
  }
  const handleSearch = (e) => {
    setSearchValue({ search: e.target.value });
  };
  const handleTriggerSearch = (search_key) => {
    setSearchTrigger(search_key);
  };
  const kbArticlesData = async () => {
    try {
      setDataLoading(true);
      const { data = {} } = await ApolloClientKnowledgeBaseService.query({
        query: GET_ARTICLES_DATA_LIST,
        variables: {
          input: {
            articleType:articleType || artTypeValue?.artType|| "all",
            categoryId: selectedGroup?.value || catValue?.cat,
            args: {
              searchKey: searchTrigger,
              page: paginationValue?.page,
              limit: Number(limitValue?.limit),
              sortBy: "_id",
              sortDir: "desc",
            },
          },
        },
        fetchPolicy: "no-cache",
      });
      setKbArtData(data);
    } catch (error) {
      // GraphQlErrorToast(error);
    }
  };
  const kbDataList = kbArtData?.getAllArticlesList?.articlesData;
  useEffect(() => {
    kbArticlesData();
    if (kbDataList?.length > 0) {
      setDataLoading(false);
      setCatValue({ cat: selectedGroup?.value });
      setartTypeValue({artType:articleType})
    } else if (kbDataList?.length === 0) {
      setDataLoading(false);
    }
  }, [
    paginationValue?.page,
    limitValue?.limit,
    searchTrigger,
    kbDataList?.length,
    selectedGroup?.value,
    articleType
  ]);

  let userName = "";
  if (localStorage.getItem("authUser")) {
    const userObj = JSON.parse(localStorage.getItem("authUser"));
    if (userObj?.role_id !== undefined) {
      userName = userObj?.user_name;
    }
  }
  const getArticleNameList = async (variables) => {
    try {
      const { data } = await ApolloClientKnowledgeBaseService.mutate({
        mutation: GET_CATEGORIES_NAME_LIST,
        variables: {},
        fetchPolicy: "network-only",
      });
      setGetCatNameList(data);
    } catch (error) {}
  };
  useEffect(() => {
    getArticleNameList();
  }, []);
  const catNameListFromApi =
    getCatNameList?.getCategoriesNameList?.categoryNameData;
  useEffect(() => {
    const optionGroup = catNameListFromApi?.map((item, itemInx) => ({
      label: item?.name?.en,
      value: item?._id,
      slug: item?.slug,
    }));
    setCategoryValue(optionGroup);
  }, [catNameListFromApi]);
  let pageIndex = Number(paginationValue.page * limitValue.limit);

  return (
    <>
      <div className=" catMain align-items-end ">
      <div className="mb-1 catOption">
          <Select
            value={selectedGroup}
            onChange={handleSelectGroup}
            options={categoryValue}
            classNamePrefix="select2-selection "
          />
        </div>
        <div className="mb-1 catOption" >
   
   <FormGroup >
           
              <select className="form-control form-select "
                id="choices-single-no-search" 
                onChange={handleArticleType}>
             <option value="">Select</option>
                    <option value="internal">internal</option>
                    <option value="external">external</option>
 </select>
                                    </FormGroup>   
                                  
     
        </div>
        <div className=" textAlignRight" lg={2}>
          <div className="searchBox">
            <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 bg-foreground">
              <Form.Control
                type="text"
                placeholder="Search"
                value={searchValue.search}
                id="listSearch"
                onChange={handleSearch}
              />
              <span
                className="search-magnifier-icon searchIcon"
                onClick={() => {
                  handleTriggerSearch(searchValue.search);
                }}
              >
                <CsLineIcons icon="search" />
              </span>
              <span className="search-delete-icon d-none">
                <CsLineIcons icon="close" />
              </span>
            </div>
          </div>
        </div>
        <div className="mb-1 textAlignRight" lg={1} md={2}>
          <button
            type="button"
            class="btn btn-outline-info
            waves-effect waves-light "
            onClick={() => {
              clearFilter();
            }}
          >
            Clear Filter{" "}
          </button>
        </div>
      </div>

      <div className=" dt-table">
        {isDataLoading ? (
          <Spinner className="ms-2 sploader" color="primary" />
        ) : null}

        {kbDataList?.length > 0 ? (
          <>
            <div className="table-responsive">
              <Table className="OrderListTable table mb-0">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Title</th>
                    <th>Created at</th>
                    <th>Last Updated by</th>
                    <th>Category Name</th>
                    <th>Type</th>
                    <th>Updated at</th>
                    <th>Status</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>

                {(kbDataList || []).map((art, index) => {
                  let status = "";
                  let stsBtn = "";
                  if (art?.status === 1) {
                    stsBtn = "bg-success";
                    status = "Published";
                  } else if (art?.status === 2) {
                    stsBtn = "bg-warning";
                    status = "Draft";
                  } else {
                    stsBtn = "bg-primary";
                    status = "Unpublished";
                  }
                  pageIndex++;

                  let date = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(art?.createdAt);

                  let cdate = date,
                    orderDt = moment(new Date(cdate)).format("Do MMM, YYYY"),
                    orderTime = moment(new Date(cdate)).format("h:mm:ss a");
                  let updatedDate = new Intl.DateTimeFormat("en-US", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  }).format(art?.updatedAt);

                  let udate = updatedDate,
                    updatedDt = moment(new Date(udate)).format("Do MMM, YYYY"),
                    updatedTime = moment(new Date(udate)).format("h:mm:ss a");

                  return (
                    <tbody key={index}>
                      <tr>
                        <th scope="row">{pageIndex}</th>
                        <td nowrap="nowrap">{art?.title?.en}</td>
                        <td nowrap="nowrap">
                          {orderDt}
                          <br />
                          {orderTime}
                        </td>
                        <td>{art?.updatedBy}</td>
                        <td>{art?.categoryName}</td>
                        <td>{art?.articleType}</td>
                        <td nowrap="nowrap">
                          {updatedDt}
                          <br />
                          {updatedTime}
                        </td>
                      
                        <td>
                          {" "}
                          <Badge className={` ${stsBtn} me-2`}>{status}</Badge>
                        </td>
                        <td>
                          <Row className="commentSec">
                            <Col className="customerComment mr-2">
                              <img src={like} alt="like" />
                              <span>{art?.like}</span>{" "}
                            </Col>
                            <Col className="customerComment">
                              <img src={unlike} alt="like" />
                              <span> {art?.dislike}</span>
                            </Col>
                          </Row>
                        </td>
                        <td>
                          <Link
                            to={`/EditNewArticle/${art?._id}`}
                            type="button"
                            className="btn btn-sm btn-outline-warning waves-effect waves-light"
                          >
                            <i className="mdi mdi-eye-outline"></i>{" "}
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </Table>
            </div>
            <div className="dataTblFooter">
              <div>
                <Dropdown
                  isOpen={btnprimary1}
                  toggle={() => setBtnprimary1(!btnprimary1)}
                >
                  <DropdownToggle tag="button" className="btn btn-primary">
                    {limitValue.limit} Items{" "}
                    <i className="mdi mdi-chevron-down" />
                  </DropdownToggle>
                  <DropdownMenu>
                    {limitByValues.map((limit, i) => {
                      return (
                        <DropdownItem
                          key={i}
                          href="#"
                          onClick={() => handleLimit(limit)}
                        >
                          {limit} Items
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div className="d-flex justify-content-center">
                <Pagination
                  id="listPage"
                  value={paginationValue.page}
                  totalPages={Math.ceil(
                    kbArtData?.getAllArticlesList?.totalRecord /
                      limitValue.limit
                  )}
                  onChange={(e) => handlePagination(e)}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <Table className="OrderListTable table mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Created at</th>
                  <th>Last Updated by</th>
                  <th>Category Name</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={7}>No Records found</td>
                </tr>
              </tbody>
            </Table>
          </>
        )}
      </div>
    </>
  );
};

export default KBArticleList;
