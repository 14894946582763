import { gql } from '@apollo/client';
export const UPDATE_ROW= gql`
mutation(
   $input:RowUpdateInput!
  ){
    updateRowsDetails(input: $input)  {
        title {
      en
      ar
    }
    _id
    previewDescription {
      en
      ar
    }
    status
    position
    gridSizeMobile
    gridSizeDesktop
    gridSizeTab
    desktopViewType
    mobileViewType
    widgets
    createdBy
    updatedBy
  }
  }`;
