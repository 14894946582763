import { gql } from '@apollo/client';
export const GET_WIDGETS_DATA_LIST= gql`
query GET_WIDGETS_DATA_LIST(
  $input:widgetsAllInput!
)
{
    getWidgetListingData(input: $input)
{
    widgetsData {
        _id
      title{
        en ar
      }
      content{
        en ar
      }
      status
      desktopImgUrl{
        en ar
      }
      mobileImgUrl{
        en ar
      }
  
      createdBy
      updatedBy
    }
    totalRecord
  }
    }`;