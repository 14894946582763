import { useState, useEffect, useSearchParams, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../../components/Common/Breadcrumb";
import "react-drawer/lib/react-drawer.css";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
import { GET_ROWS_DROPDOWN_LIST } from "../../query/AddRowDropdownList";
import { UPDATE_WIDGET } from "../../query/UpdateWidgetData";
import { GET_WIDGET_DATA_DETAIL } from "../../query/ViewWidget";
import {
  Col,
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
  Modal,
  Spinner,
} from "reactstrap";
import { useParams , Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from 'react-select';
import { Badge } from "reactstrap";
import drapIcon from "../../../assets/images/Group 174067.svg";
import dropIcon from "../../../assets/images/Group 174068.svg";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const UpdateWidgetsForm = () => {
  const { id } = useParams();
  const [saveCatLoad, setCatLoad] = useState(false);
  const [drp_secondary_lg, setDrp_secondary_lg] = useState(false);
const [rowNameList , setRowNameList] = useState("");
const [updateWidget, setUpdatewidgetData] = useState("");
  const [modal_center, setmodal_center] = useState(false);
  const [value, setValue] = useState("");
const [PlusWidgetData , setPlusWidgetData] = useState("");
  const [toggleValue, setToggleValue] = useState("Select Status");
  const [itemList, setItemList] = useState();
  const [selectWidgetType, setSelectedWidgetType] = useState("");
  const [rowName, setRowName] = useState("");

  const [selectedRow, setSelectedRow] = useState([]);
  const [articalTreeVal, setArticalsTreeData] = useState([]);
  const [widgetLoad , setwidgetLoad] = useState(false);
 
  const buttonRef = useRef(null);
  useEffect(() => {
    if (value === 0) {
      setToggleValue("Unpublished");
    } else if (value === 1) {
      setToggleValue("Published");
    } else if (value === 2) {
      setToggleValue("Draft");
    } else {
      setToggleValue("Select Status");
    }
  }, [value]);

  function tog_center() {
    setmodal_center(!modal_center);
  }
  const handleSelect = (e) => {
    setValue(e);
  };

  
  
  let stsBtn = '';
if(value === 0) {
  stsBtn = "bg-primary";
} else if (value === 1) {
  stsBtn = "bg-success";
} else if (value === 2) {
  stsBtn = "bg-warning";
} else {
  stsBtn = "bg-soft-dark"
}

const WidgetDataDetail = async () => {
  try {
    const { data = {} } = await ApolloClientKnowledgeBaseService.query({
      query: GET_WIDGET_DATA_DETAIL,
      variables: {
        input: {
          _id: id,
        },
      },
      fetchPolicy: "no-cache",
    });
    setPlusWidgetData(data);
  } catch (error) {}
};
useEffect(() => {
  WidgetDataDetail();
}, []);

const WidgetDataList = PlusWidgetData?.getWidgetDataDetail;
// useEffect(() => {
//   const arrArtData = WidgetDataList?.rows?.map((item, itemInx) => ({
//     label: item?.title?.en,
//     value:item?._id,
//     position:parseInt(item?.position)
//   }));
//   setArticalsTreeData(arrArtData);
//   const defaultArtData = artNameListFromApi?.map((item, itemInx) => (
//    item?._id
//   ));
//   setDefaultArticalsTreeData(defaultArtData)
// }, [artNameListFromApi]);

const getRowsNameList = async (variables) => {
  try {
  
   
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: GET_ROWS_DROPDOWN_LIST,
      variables:{input:{search:""}},
      fetchPolicy: 'network-only',
    });
    setRowNameList(data)
  
  } catch (error) {
    
  }
};
useEffect(() => {
  getRowsNameList()
},[]);
const rowsNameListFromApi= rowNameList?.getRowsList?.RowsData
;


useEffect(() => {
const optionGroup = rowsNameListFromApi?.map((item, itemInx) => ({

  label: item?.title?.en,
  value:item?._id,
 
}))
setRowName(optionGroup)
}, [rowsNameListFromApi]);

const handleRowsMultiSelection = (newselectedOptions) => {
  setSelectedRow(newselectedOptions);
};
useEffect(() => {
  if (selectedRow?.length === 0 || selectedRow?.length === undefined) {
    let newArray = WidgetDataList?.rows?.map((item) => ({
      label: item?.title?.en,
      value: item?._id
    }));
    if(newArray?.length >0){
      setSelectedRow(newArray);
    }

    
  }
}, [WidgetDataList, selectedRow]);
const updateWidgetData = async (events,values) => {
  const rows = [];
  selectedRow?.map((selRow) => {
  rows.push(selRow?.value);
});

  try {
    if(value !== ''){
    setwidgetLoad(true)
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: UPDATE_WIDGET,
      variables:{input:{
    
        _id:id,
        widgetData:{
          widgetType:selectWidgetType === ""? WidgetDataList?.widgetType : selectWidgetType, 
        title:{en:values?.name_e,
        ar:values?.name_a

        } ,
       rows:rows,
      content:{en:values?.description_e,ar : values?.description_a},
      status: value,
      createdBy:"Azka",
      updatedBy:"",
      desktopImgUrl:{
        en:values?.size_d,
        ar:values?.size_ma,
      },
      mobileImgUrl:{
        en:values?.size_m,
        ar:values?.size_da,
      }
        }  
      }},
      fetchPolicy: 'network-only',
    });
    setUpdatewidgetData(data)
  }
  } catch (error) {
    
  }
};
useEffect(() => {
  if (updateWidget?.updateWidgetDetails?._id !== undefined) {

    setwidgetLoad(false)
  toast.success("Widget Successfully Updated", {
      style: {
        border: "1px solid #fff",
        padding: "20px",
        color: "#ffffff",
        background: "#4CAF50",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#4CAF50",
      },
    });

}

else {
if(updateWidget?.updateWidgetDetails?._id  === ""){
  setwidgetLoad(false)
toast.error("Widget Not Updated", {
  style: {
    border: "1px solid #fff",
    padding: "20px",
    color: "#ffffff",
    background: "#f44336",
  },
  iconTheme: {
    primary: "#FFFAEE",
    secondary: "#f44336",
  },
});  
}}}, [updateWidget]);

// const handleDrop = async(droppedItem) => {
//   // Ignore drop outside droppable container
//   if (!droppedItem?.destination) return;
//   var updatedList = itemList;
//   // Remove dragged item
//   const [reorderedItem] = updatedList?.splice(droppedItem.source.index,1);
//   // Add dropped item
//   updatedList.splice(droppedItem?.destination?.index, 0, reorderedItem);
//   // Update State
//   setItemList(updatedList);
//   let posData=[];

//   itemList?.map((item,iteInx)=>{
//     let obj={
//       _id:item?.value,
//       position:iteInx
//     }
//     posData?.push(obj)
   
   

//   })
  

//   try {
   
//     const { data = {} } = await ApolloClientKnowledgeBaseService.query({
    
//       query: GET_ARTICLES_DATA_POSITION,
//       variables: {
//         input: {positionData: posData
         
//         },
//       },
//       fetchPolicy: "no-cache",
//     });
//     setKbArtPositionData(data);
//   } catch (error) {}
// };

const handleWidgetType =(e)=>{
  setSelectedWidgetType(e.target.value)
}
  return (
  
    <>
  
      <div className="page-content">
        <MetaTags>
          <title>Widget</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <DashBreadcrumb
                title="A+ Content"
                breadcrumbItem="Add New Widget"
              />

              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Col lg={8} md={8}>
                  <Card>
                    <CardBody>
                      <AvForm
                      ref={buttonRef} onValidSubmit={(e, v) => {
                          
                        updateWidgetData(e, v);
                     
                       
                        }}
                      
                      >
                         <div className="mb-3">
                                 
                                 <AvField
                   type="select"
                   name="status"
                   label="Associate to Website"
                   className="form-select"
                   onChange={handleWidgetType}
                   value ={selectWidgetType === ""? WidgetDataList?.widgetType : selectWidgetType}
                  
                 > 
                  <option value="">Select Widget Type</option>
                 <option value="Image">Image </option>
                 <option value="Content">Content</option>
                
                
                    </AvField>

                    </div>
                       
                        <div className="mb-3">
                          <AvField
                            name="name_e"
                            label="Name - English "
                            placeholder="Type Something"
                            type="text"
                            value={WidgetDataList?.title?.en}
                    
                         
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="name_a"
                            label="Name - Arabic"
                            placeholder="Type Something"
                            type="text"
                            value={WidgetDataList?.title?.ar}
                  
                          />
                        </div>
                       
                        <div className="mb-3">
                          <AvField
                            name="description_e"
                            label="Content - English"
                            type="textarea"
                            value={WidgetDataList?.content?.en}
                        
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="description_a"
                            label="Content- Arabic"
                            type="textarea"
                            value={WidgetDataList?.content?.ar}
                     
                          />
                        </div>
                       
                     <h5>Images</h5>
                    
                     
                        <div className="mb-3">
                          <AvField
                            name="size_d"
                            label="Desktop Url - English"
                            type="text"
                            value={WidgetDataList?.desktopImgUrl?.en}
                         
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="size_m"
                            label="Mobile Url - English"
                            type="text"
                            value={WidgetDataList?.mobileImgUrl?.en}
                    
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="size_ma"
                            label="Desktop Url - Arabic"
                            type="text"
                            value={WidgetDataList?.desktopImgUrl?.ar}
                 
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="size_da"
                            label="Mobile Url - Arabic"
                            type="text"
                            value={WidgetDataList?.mobileImgUrl?.ar}
            
                          />
                        </div>
                      
                      <h5>Rows</h5>
                        <div className="mb-3">
                     
                     <label>pages*</label>
              
                                  
                                    <Select
                                      value={selectedRow}
                                      isMulti
                                      name="row"
                                      options={rowName}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(value) =>
                                        handleRowsMultiSelection(value)
                                      }
                                    />
                                 
                              
                                    
                                      
                                   </div> 
                                   <div className="mb-3">
                          <AvField
                            name="position"
                            label="Position*"
                            type="text"
                            value={WidgetDataList?.position}
                   
                          />
                        </div>
                              

                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Card className="statusDetail">
                <CardHeader className="card_header">
                  <label>Status</label>
                </CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col>
                      <label>Current</label>
                    </Col>

                    <Col className="textAlignRight">
                      {" "}
                      <Badge className={` ${stsBtn} me-2`}>{toggleValue} </Badge>
                    </Col>
                  </Row>
                  <ButtonDropdown
                    isOpen={drp_secondary_lg}
                    toggle={() => setDrp_secondary_lg(!drp_secondary_lg)}
                    className="mt-2 mb-2 catbuttonDropdown"
                  >
                    <DropdownToggle
                      caret
                      color="success"
                      className="btn btn-success btn-lg "
                   
                    >
                      {widgetLoad && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      {toggleValue} &nbsp;
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdownmenu-secondary dropdown-menu-end"    onClick={() => {
                        buttonRef?.current?.submit();
                      }}>
                      {toggleValue === "Published"? (<><DropdownItem onClick={() => handleSelect(0)}>
                        Unpublished
                      </DropdownItem></>):(<> <DropdownItem onClick={() => handleSelect(1)}>
                        Published
                      </DropdownItem>
</>)}
                   
                    </DropdownMenu>
                  </ButtonDropdown>

                  <Row className="mt-3">
                    <label>ASSIGNED ROWS</label>
            
                    <Col className="colWidth">
                    <DragDropContext >
                      {/* onDragEnd={handleDrop} */}
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {WidgetDataList?.rows?.map((item, index) => (
                <Draggable key={item?.index} draggableId={item?.index} index={index}>
                  {(provided) => (
                    <div
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div>
                     <img src= {drapIcon} className="dragImage"/> 
                    
                     
                      <span>
                 {item?.title?.en}
                 </span>
                 </div>
              <div class="dragImage2"> <img src= {dropIcon}/></div>
                    </div>
                  )}
                </Draggable>
              ))}
                    {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
                    </Col>



          
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
};

export default UpdateWidgetsForm;
