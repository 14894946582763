import { gql } from '@apollo/client';
export const GET_ROWS_DROPDOWN_LIST= gql`
query GET_ROWS_DROPDOWN_LIST(
  $input:RowsListInput!
)
{
    getRowsList(input: $input) {
        RowsData{
    title{
        en 
        ar
    }
    _id
   
  }}
    }`;