import { gql } from '@apollo/client';
export const GET_ROWS_DATA_LIST= gql`
query GET_ROWS_DATA_LIST(
  $input:rowsAllInput!
)
{
    getRowsListingData(input: $input)
{
    rowsData {
        title{
        en ar
      }
      _id
      previewDescription{
        en ar
      }
      status
      gridSizeMobile
      gridSizeDesktop
      gridSizeTab
      desktopViewType
      mobileViewType
      widgets
      createdBy
      updatedBy
    }
    totalRecord
  }
    }`;