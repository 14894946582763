import { gql } from '@apollo/client';
export const GET_WIDGET_DATA_DETAIL= gql`
query GET_WIDGET_DATA_DETAIL(
  $input:widgetDataDetailInput!
)
{
    getWidgetDataDetail(input: $input)  {
      widgetType
        _id
    title {
      en
      ar
    }
    content {
      en
      ar
    }
    status
    desktopImgUrl {
      en
      ar
    }
    mobileImgUrl {
      en
      ar
    }
    rows {
      title{
        en ar
      }
      _id
    }
    position

  }


    }`;