import { gql } from '@apollo/client';
export const GET_WIDGETS_LIST= gql`
query GET_WIDGETS_LIST(
  $input:WidgetsListInput!
)
{
    getWidgetList(input: $input) {
        widgetsData{
    title{
        en 
        ar
    }
    _id
   
  }}
    }`;