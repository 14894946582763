import { gql } from '@apollo/client';
export const UPDATE_WIDGET= gql`
mutation(
   $input:WidgetUpdateInput!
  ){
    updateWidgetDetails(input: $input)  {
        _id
        widgetType
    title {
      en
      ar
    }
    content {
      en
      ar
    }
    status
    desktopImgUrl {
      en
      ar
    }
    mobileImgUrl {
      en
      ar
    }
    rows
    createdBy
    updatedBy
  }
  }`;
