import React from "react"
import { Redirect } from "react-router-dom"

//Dashboard
import Dashboard from "../pages/Dashboard/index";
// import userProfile from "../pages/Authentication/user-profile";
import KnowledgeBaseCategory from "../pages/Categories";
import AddNewCategoryForm from "../pages/Categories/components/AddNewCategory";
import KnowledgeBaseArticles from "../pages/Articles";
import AddNewArticle from "../pages/Articles/components/AddNewArticles";
import KbUpdateCategoryForm from "../pages/Categories/components/KBCategoryUpdateForm";
import EditNewArticle from "../pages/Articles/components/EditArticleDetail";
import KBDepartments from "../pages/KbDepartments";
import AddNewDepartment from "../pages/KbDepartments/components/AddDepartment";
import EditDepartment from "../pages/KbDepartments/components/EditDepartment";
// Authentication related pages
import userProfile from "../pages/Authentication/user-profile";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import APlusCategory from "../pages/A+ Content";
import APlusPages from "../pages/A+ Content/AddAPlusPages";
import APlusArtcles from "../pages/A+ Content/ArticleIndex";
import AddNewRowsForm from "../pages/A+ Content/APlusRowsForm";
import APlusWidgets from "../pages/A+ Content/WidgetIndex";
import AddNewWidgetsForm from "../pages/A+ Content/AddWidgetForm";
import UpdateAPlusPages from "../pages/A+ Content/components/UpdateAPlusPageData";
import UpdateRowsForm from "../pages/A+ Content/components/UpdateAPlusRowData";
import UpdateWidgetsForm from "../pages/A+ Content/components/UpdateAPlusWidget";
const userRoutes = [

  //dashboard
  { path: "/dashboard", component: Dashboard },

  //profile
  // { path: "/profile", component: userProfile },
  
 // knowledge Base
 { path: "/Categories", component: KnowledgeBaseCategory },
 { path: "/AddNewCategoryForm", component: AddNewCategoryForm },
 { path: "/Articles", component: KnowledgeBaseArticles },
 { path: "/AddNewArticle", component: AddNewArticle },
 {path:"/EditNewArticle/:id",component: EditNewArticle},
 {path: "/KbUpdateCategoryForm/:id", component: KbUpdateCategoryForm},
 {path:"/KBDepartments",component: KBDepartments},
 {path:"/AddNewDepartment",component: AddNewDepartment},
 {path:"/EditDepartment/:id",component: EditDepartment},

//  A+ Content

{path:"/APlusCategory",component: APlusCategory},
{path:"/APlusPages",component:APlusPages},
{path:"/APlusArtcles",component:APlusArtcles},
{path:"/AddNewRowsForm",component:AddNewRowsForm},
{path:"/APlusWidgets",component:APlusWidgets},
{path:"/AddNewWidgetsForm",component:AddNewWidgetsForm},
{path:"/UpdateAPlusPages/:id",component:UpdateAPlusPages},
{path:"/UpdateRowsForm/:id",component:UpdateRowsForm},
{path:"/UpdateWidgetsForm/:id",component:UpdateWidgetsForm},
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const authRoutes = [
  //authencation page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
];

export { userRoutes, authRoutes }
