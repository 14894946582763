import { gql } from '@apollo/client';
export const CREATE_PAGE= gql`
mutation(
   $input:PagesInput!
  ){
    createAPlusCategory(input: $input) {
    _id
    categoryName{
      en ar
    }

    sku
    rows
    status
    createdAt
    updatedAt
  }
  }`;
