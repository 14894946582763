import React from 'react'
import axios from "axios";
import { useState,useEffect } from 'react';

function Details() {

    useEffect(() => {
        // GET request using axios inside useEffect React hook
        axios.get('https://jsonplaceholder.typicode.com/users/')
            .then((response)=>{
              console.log(response);
            })
            .catch((error) => {
              //console.log(url);
              console.log(error);
             // reject(error);
            });
    
    // empty dependency array means this effect will only run once (like componentDidMount in classes)
    }, []);
  return (
    <div>Details</div>
  )
}

export default Details