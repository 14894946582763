import { useState, useEffect, useSearchParams, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../../components/Common/Breadcrumb";
import "react-drawer/lib/react-drawer.css";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import CustomRichTextEditor from "./CustomRichTextEditor";
import { GET_CATEGORIES_NAME_LIST } from "../../query/GetCatList";
import { Col, Container, Button, Card,CardBody, CardHeader,DropdownItem, ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  FormGroup,
  Spinner
  } from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation"
import Select from 'react-select';
import { useHistory } from "react-router-dom";
import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
import { Badge } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { CREATE_ARTICLES } from "../../query/createArticle";

const AddNewArticle = () => {
  const time = Date.now();
  const history = useHistory();
  const [drp_success1, setDrp_success1] = useState(false);
  const [drp_secondary_lg, setDrp_secondary_lg] = useState(false);
  const [headerTab, setHeaderTab] = useState("1")
  const [headerPillsTab, setHeaderPillsTab] = useState("1");
  const [value,setValue]=useState('');
  const [toggleValue,setToggleValue] = useState("Select Status")
  const [selectedCategory, setSelectedCategory] = useState();
  const [kbCreateArtData, setCreateArtData] = useState("");
  const [getCatNameList, setGetCatNameList] = useState("");
  const [artLoad , setArtLoad] = useState(false);
  const[articleType, setArticleType] = useState('');
  const[articleContent, setArticleContent] = useState();
  const[articleContentAr, setArticleContentAr] = useState();
  const[categoryValue, setCategoryValue] = useState("");
  const [categoryName , setCategoryName] = useState("");
  const [selectedGroup, setselectedGroup] = useState(null);


  const buttonRef = useRef(null);
  const updateArticleContentEn = (e) =>{
   
    setArticleContent(e)

}
const updateArticleContentAr = (e) =>{

  setArticleContentAr(e)

}
  useEffect(() => {
    if (value === 0) {
   
      setToggleValue("Unpublished");
    } else if (value === 1) {
   
      setToggleValue("Published");
    } else if (value === 2) {
 
      setToggleValue("Draft");
    } else {
      setToggleValue("Select Status");
    }
  }, [value]);


const handleSelect=(e)=>{

  setValue(e)
}
const handleArticleType = e => {
  setArticleType(e.target.value);

};
// const handleCategoryType = e => {
//   setCategoryIdValue(e.target.value);
//   const index = e.target.selectedIndex;
//   const el = e.target.childNodes[index]
//   const option =  el.getAttribute('id'); 
//   setSelectedCategory(option) 

// };
function handleSelectGroup(selectedGroup) {
  setselectedGroup(selectedGroup)
}
const getArticleNameList = async (variables) => {
  try {
  
   
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: GET_CATEGORIES_NAME_LIST,
      variables:{},
      fetchPolicy: 'network-only',
    });
    setGetCatNameList(data)
  
  } catch (error) {
    
  }
};
useEffect(() => {
  getArticleNameList()
},[]);


const catNameListFromApi= getCatNameList?.getCategoriesNameList?.categoryNameData;
let userName = "";
if (localStorage.getItem("authUser")) {
  const userObj = JSON.parse(localStorage.getItem("authUser"));
  if (userObj?.role_id !== undefined) {
    userName = userObj?.user_name;
  }
}
const createArticleData = async (events,values) => {
  try {
    if(value !== ''){
    setArtLoad(true)
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: CREATE_ARTICLES,
      variables:{input:{
        title: {en:values?.title_e,
        ar:values?.title_a
        } ,
      slug: values?.slug,
      categoryId:selectedGroup?.value,
      categoryName:selectedGroup?.label,
      categorySlug:selectedGroup?.slug,
      content:{en:articleContent,ar : articleContentAr},
      metaTitle:{ en: values?.metaT_e, ar : values?.metaT_a },
      metaDescription:  { en: values?.metaD_e, ar : values?.metaD_a},
      metaKeywords:{ en: values?.metak_w} ,
      status: value,
      createdBy:userName,
      updatedBy:userName,
      icon:"",
      articleType:articleType,
      position:parseInt(values?.position)
      
      }},
      fetchPolicy: 'network-only',
    });
    setCreateArtData(data)
  }
  } catch (error) {
    
  }
};
useEffect(() => {
  if (kbCreateArtData?.createArticles?._id !== undefined) {

    setArtLoad(false)
  toast.success("Article Successfully Created", {
      style: {
        border: "1px solid #fff",
        padding: "20px",
        color: "#ffffff",
        background: "#4CAF50",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#4CAF50",
      },
    });
  setArtLoad("")

}

else {
if(kbCreateArtData?.createArticles?._id  === ""){
setArtLoad(false)
toast.error("Article Not Created", {
  style: {
    border: "1px solid #fff",
    padding: "20px",
    color: "#ffffff",
    background: "#f44336",
  },
  iconTheme: {
    primary: "#FFFAEE",
    secondary: "#f44336",
  },
});  
}}}, [kbCreateArtData]);

let stsBtn = '';
if(value === 0) {
  stsBtn = "bg-primary";
} else if (value === 1) {
  stsBtn = "bg-success";
} else if (value === 2) {
  stsBtn = "bg-warning";
} else {
  stsBtn = "bg-soft-dark"
}

useEffect(() => {
const optionGroup = catNameListFromApi?.map((item, itemInx) => ({

  label: item?.name?.en,
  value:item?._id,
  slug:item?.slug
}))
setCategoryValue(optionGroup)
}, [catNameListFromApi]);
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Articles</title>
        </MetaTags>
        <Container fluid>
          <Row>
          <Col lg={8} md={8}>    
            <DashBreadcrumb
                title="Articles"
                breadcrumbItem="Add New Articles"
              />
                  
          <Row>
                    <Col>
                        <Card>
                            <CardBody>
                              
                                <AvForm
                                ref={buttonRef} onValidSubmit={(e, v) => {
                          
                                  createArticleData(e, v);
                               
                                 
                                  }}
                                >
                                         
                                 <div className="mb-3">
                                   <FormGroup className="mb-3">
                                    <AvField
                      type="select"
                      name="status"
                      label="Associate to Website"
                      className="form-select"
                      onChange={handleArticleType}
                      value =""
                      required
                    > 
                     <option value="">Select</option>
                    <option value="intrenal">internal</option>
                    <option value="external">external</option>
                   
                   
                       </AvField>
                                 </FormGroup>  
                             </div>
                                 <div className="mb-3">
                                        <AvField
                                            name="title_e"
                                            label="Title - English "
                                            placeholder="Type Something"
                                            type="text"
                                             value=""
                                             required
                                             onChange ={(e)=>{
                                              setCategoryName(e?.target?.value)
                                             }}
                                        /> 
                                    </div>

                                    <div className="mb-3">
                                        <AvField
                                            name="title_a"
                                            label="Title - Arabic"
                                            placeholder="Type Something"
                                            type="text"
                                            value=""
                                            required
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="slug"
                                            label="Slug*"
                                            type="text"
                                            value={categoryName?.replace(/[^A-Z0-9]+/ig, "-")?.toLowerCase()}
                                            required
                                          
                                          
                                        />
                                    </div>
                                    <div className="mb-3">
                        <label>Body* – English</label>
                                        <CustomRichTextEditor updateArticleContent={updateArticleContentEn} data_rtl={false} />
                                     </div>
                                     <div className="mb-3">
                                     <label>Body* – Arabic</label>
                                        <CustomRichTextEditor updateArticleContent={updateArticleContentAr}  data_rtl={true} />
                                 
                                       </div>
                           
                                   
                                  <h5>Search Engine Optimization</h5>

                                  
                                    <div className="mb-3">
                                        <AvField
                                            name="metaT_e"
                                            label="Meta Title - English"
                                            
                                            type="text"
                                            value= ""
                                        
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="metaT_a"
                                            label="Meta Title - Arabic"
                                            
                                            type="text"
                                            value= ""
                                     
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="metaD_e"
                                            label="Meta Description - English"
                                            
                                            type="text"
                                            value= ""
                                    
                                        
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <AvField
                                            name="metaD_a"
                                            label="Meta Description - Arabic"
                                            
                                            type="textarea"
                                            value= ""
                                        
                                        
                                        />
                                        </div>
                                        <div className="mb-3">
                                        <AvField
                            name="metak_w"
                            label="Meta Keywords - English"
                            type="text"
                            value=""
                         
                          />
                        </div>
                          <div className="mb-3">
                     
                  <label>Categories*</label>
                   <Select
                value={selectedGroup}
                onChange={handleSelectGroup}
                options={categoryValue}
                classNamePrefix="select2-selection"
                required
              />
                                 
                                   
                                </div> 
                                <div className="mb-3">
                                        <AvField
                            name="position"
                            label="Position*"
                            type="text"
                            value=""
                            required
                         
                          />
                        </div>
                    
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                  

                </Row> 
              
              </Col>
              <Col lg={4} md={4}>
              <Card className="statusDetail">
                <CardHeader className="card_header">
                <label>Status</label></CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col><label>Current</label>
                   
                                          
                    </Col>

                    <Col  className="textAlignRight">  
                      <Badge className={` ${stsBtn} me-2`}>{toggleValue} </Badge></Col>
                  </Row>
            
                  <ButtonDropdown
                    isOpen={drp_secondary_lg}
                    toggle={() => setDrp_secondary_lg(!drp_secondary_lg)}
                    className="mt-2 mb-2 catbuttonDropdown"
                   
                  >
                  
                    <DropdownToggle
                      caret
                      color="success"
                      className="btn btn-success btn-lg "
                      onClick={()=>{
                        buttonRef?.current?.submit()
                        
                      }}
                     
                    >
                         {artLoad && (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />)} {" "}
                      {toggleValue} &nbsp;
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdownmenu-secondary dropdown-menu-end" id ="toggleData"  onClick={()=>{
                        buttonRef?.current?.submit()
                        
                      }}>
                      
                          <DropdownItem onClick={() => handleSelect(0)}>Unpublished</DropdownItem>
                       
                          <DropdownItem  onClick={() => handleSelect(1)}>Published</DropdownItem>

                          <DropdownItem  onClick={() => handleSelect(2)}>Draft</DropdownItem>
            
                     
                    </DropdownMenu>
                  </ButtonDropdown>
                      
                 
                 
                </CardBody>
              </Card>
              </Col>
          
          </Row>
      
          <Toaster position="top-right" reverseOrder={false} />
        </Container>
      </div>
    </>
  );
};

export default AddNewArticle;
