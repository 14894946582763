import { gql } from '@apollo/client';
export const GET_PAGE_DATA_DETAIL= gql`
query GET_PAGE_DATA_DETAIL(
  $input:pageDataDetailInput!
)
{
    getPageDataDetail(input: $input) {
      pageData {
      _id
   
      categoryName{
        en ar
      }
      sku
      rows{
        _id
        title{
          en ar
        }
        position
      }
      status
      createdAt
 
      createdBy
    }

  }
    }`;