import { useState, useEffect, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../../components/Common/Breadcrumb";
import "react-drawer/lib/react-drawer.css";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import CustomRichTextEditor from "./CustomRichTextEditor";
import { GET_CATEGORIES_NAME_LIST } from "../../query/GetCatList";
import { GET_ARTICLE_DATA_DETAIL } from "../../query/ViewArticles";
import {
  Col,
  Container,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  Row,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  FormGroup,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
import { Badge } from "reactstrap";
import toast, { Toaster } from "react-hot-toast";
import { UPDATE_ARTICLES } from "../../query/EditArticleForm";
import { useParams } from "react-router-dom";
const EditNewArticle = () => {
  const history = useHistory();
  const { id } = useParams();
  const [categoryName , setCategoryName] = useState("");
  const [drp_secondary_lg, setDrp_secondary_lg] = useState(false);
  const [headerTab, setHeaderTab] = useState("1");
  const [headerPillsTab, setHeaderPillsTab] = useState("1");
  const[articleContentAr, setArticleContentAr] = useState();
  const [value, setValue] = useState("");
  const [toggleValue, setToggleValue] = useState("Select Status");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [kabArtData, setKbArtData] = useState("");
  const [categoryIdValue, setCategoryIdValue] = useState("");
  const [kbCreateArtData, setCreateArtData] = useState("");
  const [getCatNameList, setGetCatNameList] = useState("");
  const [artLoad, setArtLoad] = useState(false);
  const [articleType, setArticleType] = useState("");
  const [articleTypeVal, setArticleTypeVal] = useState("");
  const [articleContent, setArticleContent] = useState();
  const [selectedGroup, setselectedGroup] = useState(null);
  const[categoryValue, setCategoryValue] = useState("");
  const[apiCategoryValue, setApiCategoryValue] = useState("");

  const buttonRef = useRef(null);
  const updateArticleContentEn = (e) =>{
   
    setArticleContent(e)

 
 

}
const updateArticleContentAr = (e) =>{
 
  setArticleContentAr(e)

}
function handleSelectGroup(selectedGroup) {
  setselectedGroup(selectedGroup)
}

  useEffect(() => {
    if (value === 0) {
  
      setToggleValue("Unpublished");
    } else if (value === 1) {
   
      setToggleValue("Published");
    } else if (value === 2) {
  
      setToggleValue("Draft");
    } else {
      setToggleValue("Select Status");
    }
  }, [value]);

  const toggleHeader = (tab) => {
    if (headerTab !== tab) setHeaderTab(tab);
  };
  const toggleHeaderPills = (tab) => {
    if (headerPillsTab !== tab) setHeaderPillsTab(tab);
  };

  const handleSelect = (e) => {
    setValue(e);
  };
  const handleArticleType = (e) => {
    setArticleType(e.target.value);
  };


  const getCatList = async () => {
    try {
      const { data } = await ApolloClientKnowledgeBaseService.mutate({
        mutation: GET_CATEGORIES_NAME_LIST,
        variables: {},
        fetchPolicy: "network-only",
      });
      setGetCatNameList(data);
    } catch (error) {}
  };
  useEffect(() => {
    getCatList();
  }, []);


  const catNameListFromApi = getCatNameList?.getCategoriesNameList?.categoryNameData;
  useEffect(() => {
    const optionGroup = catNameListFromApi?.map((item, itemInx) => ({
    
      label: item?.name?.en,
      value:item?._id,
      slug:item?.slug
    }))
    setCategoryValue(optionGroup)
    }, [catNameListFromApi]);
   
  
  const kbArticleData = async () => {
    try {
      const { data = {} } = await ApolloClientKnowledgeBaseService.query({
        query: GET_ARTICLE_DATA_DETAIL,
        variables: {
          input: {
            _id: id,
          },
        },
        fetchPolicy: "no-cache",
      });
      setKbArtData(data);
    } catch (error) {}
  };
  useEffect(() => {
    kbArticleData();
  }, []);
  const kbDataList = kabArtData?.getArticlesDetails;
console.log(kbDataList,"kbDataList")
  useEffect(() => {
    setArticleTypeVal(kbDataList?.articleType);
      const defaultValue =[{
        label: kbDataList?.categoryName,
        value:kbDataList?.categoryId

      }]
      setApiCategoryValue(defaultValue)

   
  }, [kbDataList]);
  let userName = "";
if (localStorage.getItem("authUser")) {
  const userObj = JSON.parse(localStorage.getItem("authUser"));
  if (userObj?.role_id !== undefined) {
    userName = userObj?.user_name;
  }
}

  const updateArticleData = async (events, values) => {
    try {
      if (value !== "") {
        setArtLoad(true);
        const { data } = await ApolloClientKnowledgeBaseService.mutate({
          mutation: UPDATE_ARTICLES,
          variables: {
            input: {
                _id:id,
              articlesData: {
                title: { en: values?.title_e, ar: values?.title_a },
                slug: values?.slug,
                categoryId:
                selectedGroup === null
                ? apiCategoryValue?.[0]?.value
                    : selectedGroup?.value,
                categoryName:
                  selectedGroup === null
                    ? apiCategoryValue?.[0]?.label
                    : selectedGroup?.label,
                    content:{en:articleContent,ar : articleContentAr},
                metaTitle: { en: values?.metaT_e, ar: values?.metaT_a },
                metaDescription: { en: values?.metaD_e, ar: values?.metaD_a },
                metaKeywords: { en: values?.metak_w },
                status: value,
                createdBy:userName,
                updatedBy:userName,
                icon: "",
                articleType: articleType === "" ? articleTypeVal : articleType,
                position:kbDataList?.position === "" ? parseInt(values?.position) :kbDataList?.position 
              },
            },
          },
          fetchPolicy: "network-only",
        });
        setCreateArtData(data);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (kbCreateArtData?.updateArticlesDetails?._id !== undefined) {
      setArtLoad(false);
      setValue("")
      toast.success("Article Successfully Created", {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#4CAF50",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#4CAF50",
        },
      });
      setArtLoad("");
    } else {
      if (kbCreateArtData?.updateArticlesDetails?._id === "") {
        setArtLoad(false);
        setValue("")

        toast.error("Article Not Created", {
          style: {
            border: "1px solid #fff",
            padding: "20px",
            color: "#ffffff",
            background: "#f44336",
          },
          iconTheme: {
            primary: "#FFFAEE",
            secondary: "#f44336",
          },
        });
      }
    }
  }, [kbCreateArtData]);
  let stsBtn = '';
  if(value === 0) {
    stsBtn = "bg-primary";
  } else if (value === 1) {
    stsBtn = "bg-success";
  } else if (value === 2) {
    stsBtn = "bg-warning";
  } else {
    stsBtn = "bg-soft-dark"
  }
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Articles</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col lg={8} md={8}>
              <DashBreadcrumb
                title="Articles"
                breadcrumbItem="Add New Articles"
              />

              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <AvForm
                        ref={buttonRef}
                        onValidSubmit={(e, v) => {
                          updateArticleData(e, v);
                        }}
                      >
                        <div className="mb-3">
                          <FormGroup className="mb-3">
                            <AvField
                              type="select"
                              name="status"
                              label="Associate to Website"
                              className="form-select"
                              onChange={handleArticleType}
                              value={kbDataList?.articleType}
                            >
                              <option value="internal">internal</option>
                              <option value="external">external</option>
                            </AvField>
                          </FormGroup>
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="title_e"
                            label="Title - English "
                            placeholder="Type Something"
                            type="text"
                            value={kbDataList?.title?.en}
                            onChange ={(e)=>{
                                setCategoryName(e?.target?.value)
                               }}
                               required
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="title_a"
                            label="Title - Arabic"
                            placeholder="Type Something"
                            type="text"
                            value={kbDataList?.title?.ar}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="slug"
                            label="Slug*"
                            type="text"
                            value={categoryName === '' ? kbDataList?.slug : categoryName?.replace(/[^A-Z0-9]+/ig, "-")?.toLowerCase()}
                          required
                          />
                        </div>
                    
                        <div className="mb-3">
                        <label>Body* – English</label>
                                        <CustomRichTextEditor updateArticleContent={updateArticleContentEn} data_rtl={false} content={kbDataList?.content?.en}/>
                                     </div>
                                     <div className="mb-3">
                                     <label>Body* – Arabic</label>
                                        <CustomRichTextEditor updateArticleContent={updateArticleContentAr} data_rtl={true}  content={kbDataList?.content?.ar}/>
                                 
                                       </div>
                           
                                   
                        <h5>Search Engine Optimization</h5>

                        <div className="mb-3">
                          <AvField
                            name="metaT_e"
                            label="Meta Title - English"
                            type="text"
                            value={kbDataList?.metaTitle?.en}
                        
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaT_a"
                            label="Meta Title - Arabic"
                            type="text"
                            value={kbDataList?.metaTitle?.ar}
                        
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaD_e"
                            label="Meta Description - English"
                            type="text"
                            value={kbDataList?.metaDescription?.en}
                         
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metaD_a"
                            label="Meta Description - Arabic"
                            type="textarea"
                            value={kbDataList?.metaDescription?.ar}
                         
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="metak_w"
                            label="Meta Keywords - English"
                            type="text"
                            value={kbDataList?.metaKeywords?.en}
                       
                          />
                        </div>
                        <div className="mb-3">
                          
                            <label>Categories*</label>
                   <Select
                 value={selectedGroup === null ? apiCategoryValue : selectedGroup}
                onChange={handleSelectGroup}
                options={categoryValue}
                classNamePrefix="select2-selection"
              />
                                 
                        
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="position"
                            label="Position*"
                            type="text"
                            value={kbDataList?.position}
                             required
                          />
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Card className="statusDetail">
                <CardHeader className="card_header">
                  <label>Status</label>
                </CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col>
                      <label>Current</label>
                    </Col>

                    <Col className="textAlignRight">
                      {" "}
                 
                      <Badge className={` ${stsBtn} me-2`}>{toggleValue} </Badge>
                    </Col>
                  </Row>

                  <ButtonDropdown
                    isOpen={drp_secondary_lg}
                    toggle={() => setDrp_secondary_lg(!drp_secondary_lg)}
                    className="mt-2 mb-2 catbuttonDropdown"
                  >
                    <DropdownToggle
                      caret
                      color="success"
                      className="btn btn-success btn-lg "
                      onClick={() => {
                        buttonRef?.current?.submit();
                      }}
                    >
                      {artLoad && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      {toggleValue} &nbsp;
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu
                      className="dropdownmenu-secondary dropdown-menu-end"
                      id="toggleData"
                      onClick={()=>{
                        buttonRef?.current?.submit()
                        
                      }}
                    >
                      <DropdownItem onClick={() => handleSelect(0)}>
                        Unpublished
                      </DropdownItem>

                      <DropdownItem onClick={() => handleSelect(1)}>
                        Published
                      </DropdownItem>

                      <DropdownItem onClick={() => handleSelect(2)}>
                        Draft
                      </DropdownItem>
                    </DropdownMenu>
                  </ButtonDropdown>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Toaster position="top-right" reverseOrder={false} />
        </Container>
      </div>
    </>
  );
};

export default EditNewArticle;
