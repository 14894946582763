import { useState, useEffect, useSearchParams, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../components/Common/Breadcrumb";
import "react-drawer/lib/react-drawer.css";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import ApolloClientKnowledgeBaseService from "../../ApolloClientKBService";
import { GET_ROWS_DROPDOWN_LIST } from "../query/AddRowDropdownList";
import { CREATE_PAGE } from "../query/CreatePageApi";
import {
  Col,
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
  Modal,
  Spinner,
} from "reactstrap";
import { useParams , Link} from "react-router-dom";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from 'react-select';
import { Badge } from "reactstrap";
const APlusPages = () => {
  const [inputValue, setInputValue] = useState('');
  const [saveCatLoad, setCatLoad] = useState("");
  const [rowName, setRowName] = useState("");
  const [rowNameList , setRowNameList] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  console.log(saveCatLoad,"saveCatLoad")
  const [drp_secondary_lg, setDrp_secondary_lg] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [value, setValue] = useState("");
  console.log(value,"value")
  const [toggleValue, setToggleValue] = useState("Select Status");
  const [pageLoad , setPageLoad] = useState(false);
  const [createPage, setCreatePageData] = useState("");
  const buttonRef = useRef(null);

  function tog_center() {
    setmodal_center(!modal_center);
  }
  useEffect(() => {
    if (value === 0) {
      setToggleValue("Unpublished");
    } else if (value === 1) {
      setToggleValue("Published");
    } else if (value === 2) {
      setToggleValue("Draft");
    } else {
      setToggleValue("Select Status");
    }
  }, [value]);

 
  let stsBtn = '';
if(value === 0) {
  stsBtn = "bg-primary";
} else if (value === 1) {
  stsBtn = "bg-success";
} else if (value === 2) {
  stsBtn = "bg-warning";
} else {
  stsBtn = "bg-soft-dark"
}

const handleSelect = (e) => {
  setValue(e);
};
const handleInputChange = (event) => {
  setInputValue(event.target.value);
};


const getRowsNameList = async (variables) => {
  try {
  
   
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: GET_ROWS_DROPDOWN_LIST,
      variables:{input:{search:""}},
      fetchPolicy: 'network-only',
    });
    setRowNameList(data)
  
  } catch (error) {
    
  }
};
useEffect(() => {
  getRowsNameList()
},[]);
const rowsNameListFromApi= rowNameList?.getRowsList?.RowsData
;

useEffect(() => {
const optionGroup = rowsNameListFromApi?.map((item, itemInx) => ({

  label: item?.title?.en,
  value:item?._id,
 
}))
setRowName(optionGroup)
}, [rowsNameListFromApi]);

const handleRowsMultiSelection = (newselectedOptions) => {
  setSelectedRow(newselectedOptions);
};

const createPageData = async (events,values) => {
  console.log(values,"values")
  const rows = [];
  selectedRow?.map((selRow) => {
  rows.push(selRow?.value);
});
const array =  inputValue.split(',')?.map((item) => item.trim());
  try {
    if(value !== ''){
    setPageLoad(true)
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: CREATE_PAGE,
      variables:{input:{
      categoryName:{en:values?.cat_en, ar:values?.cat_ar},
     
      sku:array,
      status: value,
      rows:rows,
      createdBy:"Azka",
      updatedBy:"",
    
      
      }},
      fetchPolicy: 'network-only',
    });
    setCreatePageData(data)
  }
  } catch (error) {
    if(error !== undefined)
    {
      const errData = JSON?.parse(JSON.stringify(error));
      console.log("errData----",JSON?.stringify(errData));
      console.log("ccccc----", errData?.graphQLErrors?.[0]?.message?.message);
    }
  }
};

useEffect(() => {
  if (createPage?.createAPlusCategory?._id !== undefined) {
    setPageLoad(false)
  toast.success("Page Successfully Created", {
      style: {
        border: "1px solid #fff",
        padding: "20px",
        color: "#ffffff",
        background: "#4CAF50",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#4CAF50",
      },
    });

}

else {
if(createPage?.createAPlusCategory?._id  === ""){
  setPageLoad(false)
toast.error("Page Successfully Created", {
  style: {
    border: "1px solid #fff",
    padding: "20px",
    color: "#ffffff",
    background: "#f44336",
  },
  iconTheme: {
    primary: "#FFFAEE",
    secondary: "#f44336",
  },
});  
}}}, [createPage]);

  return (
  
    <>
  
      <div className="page-content">
        <MetaTags>
          <title>Categories</title>
        </MetaTags>
        <Container fluid>
          <Row className="catHeight">
            <Col>
              <DashBreadcrumb
                title="A+ Content"
                breadcrumbItem="Add New A+ Page"
              />

              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Col lg={8} md={8}>
                  <Card>
                    <CardBody>
                      <AvForm
                        ref={buttonRef}
                        onValidSubmit={(e, v) => {
                          
                          createPageData(e, v);
                       
                         
                          }}
                      >
                           
                        <div className="mb-3">
                          <AvField
                            name="cat_en"
                            label="Category Name "
                            placeholder="Type Something"
                            type="text"   
                          value=""
                         
                           
                          
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="cat_ar"
                            label="Category Name "
                            placeholder="Type Something"
                            type="text"
                          value=""
                         
                           
                          
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="sku"
                            label="List of Product SKU*"
                            placeholder="Type Something"
                            type="textarea"
                            value={inputValue}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <h5>Rows</h5>
                        <div className="mb-3">
                  
                                    <Select
                                      defaultValue={selectedRow}
                                      isMulti
                                      name="row"
                                      options={rowName}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(value) =>
                                        handleRowsMultiSelection(value)
                                      }
                                    />             
                        </div> 

                      
                                 
                              

                           
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Card className="statusDetail">
                <CardHeader className="card_header">
                  <label>Status</label>
                </CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col>
                      <label>Current</label>
                    </Col>

                    <Col className="textAlignRight">
                      {" "}
                      <Badge className={` ${stsBtn} me-2`}>{toggleValue} </Badge>
                    </Col>
                  </Row>
                  <ButtonDropdown
                    isOpen={drp_secondary_lg}
                    toggle={() => setDrp_secondary_lg(!drp_secondary_lg)}
                    className="mt-2 mb-2 catbuttonDropdown"
                  >
                    <DropdownToggle
                      caret
                      color="success"
                      className="btn btn-success btn-lg "
                   
                    >
                      {pageLoad && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      {toggleValue} &nbsp;
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdownmenu-secondary dropdown-menu-end"    onClick={() => {
                        buttonRef?.current?.submit();
                      }}>
                        {
                          toggleValue ===  "Published" ? ( <DropdownItem onClick={() => handleSelect(0)}>
                          Unpublished
                        </DropdownItem>) :    <DropdownItem onClick={() => handleSelect(1)}>
                        Published
                      </DropdownItem>
                        }
                     

                   

                   

                      
                    </DropdownMenu>
                  </ButtonDropdown>


                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
};

export default APlusPages;
