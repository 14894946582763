import { useState, useEffect, useSearchParams, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../../components/Common/Breadcrumb";
import "react-drawer/lib/react-drawer.css";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import { useParams , Link} from "react-router-dom";
import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
import { GET_WIDGETS_LIST } from "../../query/GetWidgetsList";
import { UPDATE_ROW } from "../../query/UpdateRowData";
import { GET_ROW_DATA_DETAIL } from "../../query/ViewRow";
import drapIcon from "../../../assets/images/Group 174067.svg";
import dropIcon from "../../../assets/images/Group 174068.svg";
import { GET_WIDGET_DATA_POSITION } from "../../query/updateWidgetPosition";
import {
  Col,
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
  Modal,
  Spinner,
} from "reactstrap";

// import drapIcon from "../../assets/images/Group 174067.svg";
// import dropIcon from "../../assets/images/Group 174068.svg";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from 'react-select';
import { Badge } from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
const UpdateRowsForm = () => {
    const { id } = useParams();
  const [saveCatLoad, setCatLoad] = useState(false);
  const [itemList, setItemList] = useState();
  console.log(itemList,"itemList")
  const [itemRowList, setItemRowList] = useState();
  const [drp_secondary_lg, setDrp_secondary_lg] = useState(false);
const [widgetsName , setWidgetsNameList] = useState("");
  const [modal_center, setmodal_center] = useState(false);
  const [value, setValue] = useState("");
  const [toggleValue, setToggleValue] = useState("Select Status");
  const [kabCatError, setKbCatError] = useState("");
  const [selectedWidgets, setSelectedWidgets] = useState([]);
  const [widgetNameData , setwidgetName] = useState("")
  const [ rowLoad, setRowLoad] = useState(false);
  const [updateRow, setUpdateRowData] = useState("");
  const [viewTypeDesk, setViewTypeDesk] = useState("");
  const [viewTypeMob, setViewTypeMob] = useState("");
  console.log(viewTypeMob,"viewTypeMob")
  const [PlusRowData, setPlusRowData] = useState("");
  const [kabArtPosition, setKbArtPositionData] =useState("");
  const buttonRef = useRef(null);
  useEffect(() => {
    if (value === 0) {
      setToggleValue("Unpublished");
    } else if (value === 1) {
      setToggleValue("Published");
    } else if (value === 2) {
      setToggleValue("Draft");
    } else {
      setToggleValue("Select Status");
    }
  }, [value]);

  function tog_center() {
    setmodal_center(!modal_center);
  }
  const handleSelect = (e) => {
    setValue(e);
  };

  
  let stsBtn = '';
if(value === 0) {
  stsBtn = "bg-primary";
} else if (value === 1) {
  stsBtn = "bg-success";
} else if (value === 2) {
  stsBtn = "bg-warning";
} else {
  stsBtn = "bg-soft-dark"
}
const getWidgetsNameList = async (variables) => {
  try {
  
   
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: GET_WIDGETS_LIST,
      variables:{input:{search:""}},
      fetchPolicy: 'network-only',
    });
    setWidgetsNameList(data)
  
  } catch (error) {
    
  }
};
useEffect(() => {
  getWidgetsNameList()
},[]);
const widgetsNameListFromApi= widgetsName?.getWidgetList?.widgetsData
;

useEffect(() => {
const optionGroup = widgetsNameListFromApi?.map((item, itemInx) => ({

  label: item?.title?.en,
  value:item?._id,
 
}))
setwidgetName(optionGroup)
}, [widgetsNameListFromApi]);
const handleWidgetsMultiSelection = (newselectedOptions) => {
  setSelectedWidgets(newselectedOptions);
};
const RowDataDetail = async () => {
    try {
      const { data = {} } = await ApolloClientKnowledgeBaseService.query({
        query: GET_ROW_DATA_DETAIL,
        variables: {
          input: {
            _id: id,
          },
        },
        fetchPolicy: "no-cache",
      });
      setPlusRowData(data);
    } catch (error) {}
  };
  useEffect(() => {
    RowDataDetail();
  }, []);
 
  const RowDataList = PlusRowData?.getRowData;
  useEffect(()=>{
    setItemList(RowDataList)
    setItemRowList(RowDataList?.widgets);
 
  }, [RowDataList])
   
  useEffect(() => {
    if (selectedWidgets?.length === 0 || selectedWidgets?.length === undefined) {
      let newArray = RowDataList?.widgets?.map((item) => ({
        label: item?.title?.en,
        value: item?._id
      }));
console.log(newArray,"newArray")
      if(newArray?.length>0){
        setSelectedWidgets(newArray);
      }
  
      
    }
  }, [RowDataList, selectedWidgets]);


const UpdateRowData = async (events,values) => {
  const rows = [];
  selectedWidgets?.map((selWidget) => {
  rows.push(selWidget?.value);
});

  try {
    if(value !== ''){
    setRowLoad(true)
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: UPDATE_ROW,
      variables:{input:{
        _id:id,
        rowData:{
        title: {en:values?.name_e,
        ar:values?.name_a
        } ,
        gridSizeMobile: Number(values?.size_m),
        gridSizeDesktop:  Number(values?.size_d),
        gridSizeTab:Number(values?.size_t),
        desktopViewType:viewTypeDesk === ""? RowDataList?.desktopViewType : viewTypeDesk,
        mobileViewType:viewTypeMob === ""?RowDataList?.mobileViewType : viewTypeDesk,
       previewDescription:{en:values?.description_e,ar : values?.description_a},
      status: value,
      position:Number(values?.position),
      widgets:rows,

      createdBy:"Azka",
      updatedBy:"Azka",
    
        }
      }},
      fetchPolicy: 'network-only',
    });
    setUpdateRowData(data)
  }
  } catch (error) {
    
  }
};
useEffect(() => {
  if (updateRow?.updateRowsDetails?._id !== undefined) {

    setRowLoad(false)
  toast.success("Row Successfully Updated", {
      style: {
        border: "1px solid #fff",
        padding: "20px",
        color: "#ffffff",
        background: "#4CAF50",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#4CAF50",
      },
    });

}

else {
if(updateRow?.updateRowsDetails?._id === ""){
  setRowLoad(false)
toast.error("Row Not Updated", {
  style: {
    border: "1px solid #fff",
    padding: "20px",
    color: "#ffffff",
    background: "#f44336",
  },
  iconTheme: {
    primary: "#FFFAEE",
    secondary: "#f44336",
  },
});  
}}}, [updateRow]);

const handleDrop = async(droppedItem) => {
  console.log(droppedItem?.droppedItem)
  // Ignore drop outside droppable container
  if (!droppedItem?.destination) return;
  var updatedList = itemList?.widgets;
  console.log(updatedList,"updatedList")
  // Remove dragged item
  const [reorderedItem] = updatedList?.splice(droppedItem?.source?.index,1);
  // Add dropped item
  updatedList.splice(droppedItem?.destination?.index, 0, reorderedItem);
  // Update State
  setItemRowList(updatedList);
  let posData=[];

  

  itemList?.widgets?.map((item,iteInx)=>{
    let obj={
      _id:item?._id,
      position:iteInx
    }
    posData?.push(obj)
   
   

  })
  const optionList =itemList?.widgets?.map((items, itemInxx) => ({

    label: items?.title?.en,
    value:items?._id,
   
  }))
  setSelectedWidgets(optionList)

  try {
   console.log("postData---------",posData)
    const { data = {} } = await ApolloClientKnowledgeBaseService.query({
    
      query: GET_WIDGET_DATA_POSITION,
      variables: {
        input: {positionData: posData
         
        },
      },
      fetchPolicy: "no-cache",
    });
    setKbArtPositionData(data);
  } catch (error) {}
};


useEffect(() => {
  const kabArtPositionMessage= kabArtPosition?.updateRowsPositions?.result;
console.log("kabArtPositionMessage---------",kabArtPositionMessage)
  if (kabArtPositionMessage === "Success") {
    setCatLoad(false);
    toast.success("Article Successfully Updated", {
      style: {
        border: "1px solid #fff",
        padding: "20px",
        color: "#ffffff",
        background: "#4CAF50",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#4CAF50",
      },
    });
  
    // setCreatePageData("");
  } else {
    if (kabArtPositionMessage=== "Failed") {
      setCatLoad(false);
      toast.error("Article Not Updated", {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#f44336",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#f44336",
        },
      });
    }
  }
}, [kabArtPosition]);


  return (
  
    <>
  
      <div className="page-content">
        <MetaTags>
          <title>Categories</title>
        </MetaTags>
        <Container fluid>
          <Row>
            <Col>
              <DashBreadcrumb
                title="A+ Content"
                breadcrumbItem="Add New Row"
              />

              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Col lg={8} md={8}>
                  <Card>
                    <CardBody>
                      <AvForm
                          ref={buttonRef} onValidSubmit={(e, v) => {
                          
                            UpdateRowData(e, v);
                         
                           
                            }}
                      
                      >
                       
                        <div className="mb-3">
                          <AvField
                            name="name_e"
                            label="Name - English "
                            placeholder="Type Something"
                            type="text"
                            value={RowDataList?.title?.en}
                            required
                         
                          />
                        </div>

                        <div className="mb-3">
                          <AvField
                            name="name_a"
                            label="Name - Arabic"
                            placeholder="Type Something"
                            type="text"
                            value={RowDataList?.title?.ar}
                     
                          />
                        </div>
                       
                        <div className="mb-3">
                          <AvField
                            name="description_e"
                            label="Short Description - English"
                            type="textarea"
                            value={RowDataList?.previewDescription?.en}
                  
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="description_a"
                            label="Short Description - Arabic"
                            type="textarea"
                            value={RowDataList?.previewDescription?.ar}
                      
                          />
                        </div>
                        <div className="mb-3">
                        <h6 style={{ color: "#495057" }}>
                          Show as Grid in Desktop View*
                        </h6>
                     
                          <FormGroup className="mb-3">
                            <Row>
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value={RowDataList?.desktopViewType}
                                    name="grid"
                                    checked={RowDataList?.desktopViewType === 'grid' ? true : false}
                                    id="gridRadios"
                                    onChange={(e)=>{
                                      setViewTypeDesk(e?.target?.value)
                                    }}
                                 
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="gridRadios"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </Col>
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value={RowDataList?.desktopViewType}
                                    checked={RowDataList?.desktopViewType === 'no grid' ? true : false}
                                    name="grid"
                                    id="noGridRadios"
                                    onChange={(e)=>{
                                      setViewTypeDesk(e?.target?.value)
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="noGridRadios"
                                  >
                                    No
                                  </label>
                                </div>
                              </Col>
                          
                            </Row>
                          </FormGroup>
                    
                      </div>
                      <div className="mb-3">
                        <h6 style={{ color: "#495057" }}>
                          Show as Grid in Mobile View*
                        </h6>
                     
                          <FormGroup className="mb-3">
                            <Row>
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value={RowDataList?.mobileViewType}
                                    name="mgrid"
                                    onChange={(e)=>{
                                      setViewTypeMob(e?.target?.value)
                                    }}
                                    id="mRadios"
                                  checked={RowDataList?.mobileViewType === 'grid' ? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="mRadios"
                                  >
                                    Yes
                                  </label>
                                </div>
                              </Col>
                              <Col>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    value={RowDataList?.mobileViewType}
                                    name="mgrid"
                                    id="nRadios"
                                    onChange={(e)=>{
                                      setViewTypeMob(e?.target?.value)
                                    }}
                                    checked={RowDataList?.mobileViewType === 'no grid'? true : false}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="nRadios"
                                  >
                                    No
                                  </label>
                                </div>
                              </Col>
                          
                            </Row>
                          </FormGroup>
                    
                      </div>
                        <div className="mb-3">
                          <AvField
                            name="size_m"
                            label="Grid Size Mobile"
                            type="number"
                            value={RowDataList?.gridSizeMobile}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="size_d"
                            label="Grid Size Desktop"
                            type="number"
                            value={RowDataList?.gridSizeDesktop}
                            required
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="size_t"
                            label="Grid Size Tab"
                            type="number"
                            value={RowDataList?.gridSizeTab}
                            required
                          />
                        </div>
                      
                        <div className="mb-3">
                     
                     <label>Widgets*</label>
              
                                  
                                    <Select
                                      value={selectedWidgets}
                                      isMulti
                                      name="widget"
                                      options={widgetNameData}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(value) =>
                                        handleWidgetsMultiSelection(value)
                                      }
                                    />
                                 
                              
                                    
                                      
                                   </div> 
                                   <div className="mb-3">
                          <AvField
                            name="position"
                            label="Position*"
                            type="text"
                            value={RowDataList?.position}
                             required
                          />
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Card className="statusDetail">
                <CardHeader className="card_header">
                  <label>Status</label>
                </CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col>
                      <label>Current</label>
                    </Col>

                    <Col className="textAlignRight">
                      {" "}
                      <Badge className={` ${stsBtn} me-2`}>{toggleValue} </Badge>
                    </Col>
                  </Row>
                  <ButtonDropdown
                    isOpen={drp_secondary_lg}
                    toggle={() => setDrp_secondary_lg(!drp_secondary_lg)}
                    className="mt-2 mb-2 catbuttonDropdown"
                  >
                    <DropdownToggle
                      caret
                      color="success"
                      className="btn btn-success btn-lg "
                   
                    >
                      {rowLoad && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      {toggleValue} &nbsp;
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdownmenu-secondary dropdown-menu-end"    onClick={() => {
                        buttonRef?.current?.submit();
                      }}>
                     {toggleValue === "Published"? (<><DropdownItem onClick={() => handleSelect(0)}>
                        Unpublished
                      </DropdownItem></>):(<> <DropdownItem onClick={() => handleSelect(1)}>
                        Published
                      </DropdownItem>
</>)}

                   
                    </DropdownMenu>
                  </ButtonDropdown>
      
                  <Row className="mt-3">
                    <label>ASSIGNED WIDGETS</label>
            
                    <Col className="colWidth">
                 
                    <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {console.log(itemRowList,"ajkhkja")}
              {itemRowList?.map((item, index) => (
                <Draggable key={item?._id} draggableId={item?._id} index={index}>
                  {(provided) => (
                    <div
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div>
                     <img src= {drapIcon} className="dragImage"/> 
                    
                     
                      <span>
                {item?.title?.en}{"     "}
                 </span>
                 </div>
              <div class="dragImage2"> <Link to={`/EditNewArticle/${item?.value}`}><img src= {dropIcon}/></Link></div>
                    </div>
                  )}
                </Draggable>
              ))}
                    {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
                    </Col>



          
                  </Row> 

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
};

export default UpdateRowsForm;
