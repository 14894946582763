import { gql } from '@apollo/client';
export const CREATE_ROW= gql`
mutation(
   $input:RowsInput!
  ){
    createRows(input: $input) {
        title {
      en
      ar
    }
    _id
    previewDescription {
      en
      ar
    }
    status
    position
    gridSizeMobile
    gridSizeDesktop
    gridSizeTab
    desktopViewType
    mobileViewType
    widgets
    createdBy
    updatedBy
  }
  }`;
