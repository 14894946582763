import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../components/Common/Breadcrumb";
import { Col, Container, Row , Button, Table} from "reactstrap";
import { Link } from "react-router-dom"

import KBDepartmentList from "./components/DepartmentsList";

const KBDepartments = () => {
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Departments</title>
        </MetaTags>
        <Container fluid>
          <Row>
          <Col>      <DashBreadcrumb
                title="Knowledge Base"
                breadcrumbItem="Departments"
              /></Col>
          <Col md="auto" sm="auto" xs="auto" className="MobileView">
      
          <Link  className="btn btn-primary" to="/AddNewDepartment"
          
          >
            Add Department
           

          </Link>
        </Col>
     
          </Row>
          <KBDepartmentList/>
</Container>
       
      </div>
    </>
  );
};

export default KBDepartments;
