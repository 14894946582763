import { gql } from '@apollo/client';
export const GET_ROW_DATA_DETAIL= gql`
query GET_ROW_DATA_DETAIL(
  $input:rowDataDetailInput!
)
{
    getRowData(input: $input)  {
    title {
      en
      ar
    }
    previewDescription {
      en
      ar
    }
    status
    position
    gridSizeMobile
    gridSizeDesktop
    gridSizeTab
    desktopViewType
    mobileViewType
    widgets {
      _id
      title
   {
     en ar
   }
  
    }
    createdBy
    updatedBy
  }


    }`;