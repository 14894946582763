import { gql } from '@apollo/client';
export const UPDATE_PAGE= gql`
mutation(
   $input:PageUpdateInput!
  ){
    updatePageDetails(input: $input)  {
        _id
   
    categoryName{
      en ar
    }
    sku
    rows
    status
    createdAt
    updatedAt
    createdBy
  }
  }`;
