import { gql } from '@apollo/client';
export const CREATE_WIDGETS= gql`
mutation(
   $input:WidgetsInput!
  ){
    createWidgets(input: $input) {
      widgetType
    _id
    title {
      en
      ar
    }
    content {
      en
      ar
    }
    status
    desktopImgUrl {
      en
      ar
    }
    mobileImgUrl {
      en
      ar
    }
    rows 
    position
    createdBy
    updatedBy
  }
  }`;
