
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../components/Common/Breadcrumb";
import { Col, Container, Row , Button, Table} from "reactstrap";
import { Link } from "react-router-dom"
import APlusWidgetsList from "./WidgetsList";
const APlusWidgets= () => {
  return (
    <>
      <div className="page-content">
        <MetaTags>
          <title>Widgets</title>
        </MetaTags>
        <Container fluid>
          <Row>
          <Col>      <DashBreadcrumb
                title="A+ Content"
                breadcrumbItem="Widgets"
              /></Col>
          <Col md="auto" sm="auto" xs="auto" className="MobileView">
      
          <Link  className="btn btn-primary" to="/AddNewWidgetsForm"
          
          >
            Add Entry
           

          </Link>
        </Col>
     
          </Row>
          <APlusWidgetsList/>
</Container>
       
      </div>
    </>
  );
};

export default APlusWidgets;
