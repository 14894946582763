import { useState, useEffect, useSearchParams, useRef } from "react";
import { MetaTags } from "react-meta-tags";
import { DashBreadcrumb } from "../../../components/Common/Breadcrumb";
import "react-drawer/lib/react-drawer.css";
import "react-datepicker/dist/react-datepicker.css";
import "flatpickr/dist/themes/material_blue.css";
import ApolloClientKnowledgeBaseService from "../../../ApolloClientKBService";
import { GET_PAGE_DATA_DETAIL } from "../../query/ViewPage";
import { UPDATE_PAGE } from "../../query/UpdatePaageData";
import { GET_ROWS_DROPDOWN_LIST } from "../../query/AddRowDropdownList";
import { GET_ROWS_DATA_POSITION } from "../../query/updateRowPosition";
import {
  Col,
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  DropdownItem,
  ButtonDropdown,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Row,
  Modal,
  Spinner,
} from "reactstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import drapIcon from "../../../assets/images/Group 174067.svg";
import dropIcon from "../../../assets/images/Group 174068.svg";
import toast, { Toaster } from "react-hot-toast";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { useParams } from "react-router-dom";
import Select from 'react-select';
import { Badge } from "reactstrap";
const UpdateAPlusPages = () => {
    const { id } = useParams();
  const [inputValue, setInputValue] = useState('');

  const [valueArray, setValueArray] = useState([]);
  const [itemList, setItemList] = useState();
  const [itemRowList, setItemRowList] = useState();
  const [saveCatLoad, setCatLoad] = useState("");
  const [rowName, setRowName] = useState("");
  const [rowNameList , setRowNameList] = useState("");
  const [selectedRow, setSelectedRow] = useState([]);
  const [kabArtPosition, setKbArtPositionData] =useState("");
  const [drp_secondary_lg, setDrp_secondary_lg] = useState(false);
  const [modal_center, setmodal_center] = useState(false);
  const [value, setValue] = useState("");

  const [toggleValue, setToggleValue] = useState("Select Status");
  const [pageLoad , setPageLoad] = useState(false);
  const [createPage, setCreatePageData] = useState("");
  const buttonRef = useRef(null);
  const [plusCatData, setPlusCatData] = useState("");

  function tog_center() {
    setmodal_center(!modal_center);
  }
  useEffect(() => {
    if (value === 0) {
      setToggleValue("Unpublished");
    } else if (value === 1) {
      setToggleValue("Published");
    } else if (value === 2) {
      setToggleValue("Draft");
    } else {
      setToggleValue("Select Status");
    }
  }, [value]);

 
  let stsBtn = '';
if(value === 0) {
  stsBtn = "bg-primary";
} else if (value === 1) {
  stsBtn = "bg-success";
} else if (value === 2) {
  stsBtn = "bg-warning";
} else {
  stsBtn = "bg-soft-dark"
}
 


const handleSelect = (e) => {
  setValue(e);
};
const handleInputChange = (event) => {
  setInputValue(event.target.value);
};


const getRowsNameList = async (variables) => {
  try {
  
   
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: GET_ROWS_DROPDOWN_LIST,
      variables:{input:{search:""}},
      fetchPolicy: 'network-only',
    });
    console.log("setRowNameList---------",data)
    setRowNameList(data)
  
  } catch (error) {
    
  }
};
useEffect(() => {
  getRowsNameList()
},[]);
const rowsNameListFromApi= rowNameList?.getRowsList?.RowsData
;

useEffect(() => {
const optionGroup = rowsNameListFromApi?.map((item, itemInx) => ({

  label: item?.title?.en,
  value:item?._id,
 
}))
setRowName(optionGroup)
}, [rowsNameListFromApi]);

const handleRowsMultiSelection = (newselectedOptions) => {
  setSelectedRow(newselectedOptions);
};



const APlusPageData = async () => {
    try {
      const { data = {} } = await ApolloClientKnowledgeBaseService.query({
        query: GET_PAGE_DATA_DETAIL,
        variables: {
          input: {
            _id: id,
          },
        },
        fetchPolicy: "no-cache",
      });
      setPlusCatData(data);
    } catch (error) {}
  };
  useEffect(() => {
    APlusPageData();
  }, []);
 
  const kbDataList = plusCatData?.getPageDataDetail?.pageData;
  
  useEffect(()=>{
    setItemList(kbDataList)
    setItemRowList(kbDataList?.rows);
  }, [kbDataList])
  useEffect(() => {
    if (selectedRow?.length === 0 || selectedRow?.length === undefined) {
      let newArray = kbDataList?.rows?.map((item) => ({
        label: item?.title?.en,
        value: item?._id
      }));
console.log(newArray,"newArray")
      if(newArray?.length>0){
        setSelectedRow(newArray);
      }
  
      
    }
  }, [kbDataList, selectedRow]);

  useEffect(() => {
    if(valueArray?.length === 0 || valueArray?.length === undefined){
        setValueArray(kbDataList?.sku)
    }
    
  },[kbDataList]);
  const rows = [];
  if(selectedRow?.length > 0){ selectedRow?.map((selRow) => {
    rows.push(selRow?.value);
  });}

const updatePageData = async (events,values) => {
const array =  inputValue === '' ? kbDataList?.sku: inputValue?.split(',')?.map((item) => item.trim())
    

  try {
    if(value !== ''){
    setPageLoad(true)
    const { data } = await ApolloClientKnowledgeBaseService.mutate({
      mutation: UPDATE_PAGE,
      variables:{input:{
        _id:id,
        pageData:{
          categoryName: {en:values?.cat_en,
            ar:values?.cat_ar
            } ,
      sku:array,
      status: value,
      rows:rows,
      createdBy:"Azka",
      updatedBy:"Azka"
    
    
        }
      }},
      fetchPolicy: 'network-only',
    });
    setCreatePageData(data)
  }
  } catch (error) {
    
  }
};

useEffect(() => {
  if (createPage?.updatePageDetails?._id !== undefined) {
    setPageLoad(false)
  toast.success("Page Successfully Updated", {
      style: {
        border: "1px solid #fff",
        padding: "20px",
        color: "#ffffff",
        background: "#4CAF50",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#4CAF50",
      },
    });

}

else {
if(createPage?.updatePageDetails?._id  === ""){
  setPageLoad(false)
toast.error("Page Successfully Updated", {
  style: {
    border: "1px solid #fff",
    padding: "20px",
    color: "#ffffff",
    background: "#f44336",
  },
  iconTheme: {
    primary: "#FFFAEE",
    secondary: "#f44336",
  },
});  
}}}, [createPage]);
const handleDrop = async(droppedItem) => {
  // Ignore drop outside droppable container
  if (!droppedItem?.destination) return;
  var updatedList = itemList?.rows;
  console.log(updatedList,"updatedList")
  // Remove dragged item
  const [reorderedItem] = updatedList?.splice(droppedItem?.source?.index,1);
  // Add dropped item
  updatedList.splice(droppedItem?.destination?.index, 0, reorderedItem);
  // Update State
  setItemRowList(updatedList);
  let posData=[];

  

  itemList?.rows?.map((item,iteInx)=>{
    let obj={
      _id:item?._id,
      position:iteInx
    }
    posData?.push(obj)
   
   

  })
  const optionList =itemList?.rows?.map((items, itemInxx) => ({

    label: items?.title?.en,
    value:items?._id,
   
  }))
  setSelectedRow(optionList)

  try {
   console.log("postData---------",posData)
    const { data = {} } = await ApolloClientKnowledgeBaseService.query({
    
      query: GET_ROWS_DATA_POSITION,
      variables: {
        input: {positionData: posData
         
        },
      },
      fetchPolicy: "no-cache",
    });
    setKbArtPositionData(data);
  } catch (error) {}
};


useEffect(() => {
  const kabArtPositionMessage= kabArtPosition?.updateRowsPositions?.result;
console.log("kabArtPositionMessage---------",kabArtPositionMessage)
  if (kabArtPositionMessage === "Success") {
    setCatLoad(false);
    toast.success("Article Successfully Updated", {
      style: {
        border: "1px solid #fff",
        padding: "20px",
        color: "#ffffff",
        background: "#4CAF50",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#4CAF50",
      },
    });
  
    // setCreatePageData("");
  } else {
    if (kabArtPositionMessage=== "Failed") {
      setCatLoad(false);
      toast.error("Article Not Updated", {
        style: {
          border: "1px solid #fff",
          padding: "20px",
          color: "#ffffff",
          background: "#f44336",
        },
        iconTheme: {
          primary: "#FFFAEE",
          secondary: "#f44336",
        },
      });
    }
  }
}, [kabArtPosition]);


  return (
  
    <>
  
      <div className="page-content">
        <MetaTags>
          <title>Categories</title>
        </MetaTags>
        <Container fluid>
          <Row className="catHeight">
            <Col>
              <DashBreadcrumb
                title="Categories"
                breadcrumbItem="Add New Category"
              />

              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Col lg={8} md={8}>
                  <Card>
                    <CardBody>
                      <AvForm
                        ref={buttonRef}
                        onValidSubmit={(e, v) => {
                          
                            updatePageData(e, v);
                       
                         
                          }}
                      >
                           
                           <div className="mb-3">
                          <AvField
                            name="cat_en"
                            label="Category Name "
                            placeholder="Type Something"
                            type="text"   
                            value={kbDataList?.categoryName?.en}
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="cat_ar"
                            label="Category Name "
                            placeholder="Type Something"
                            type="text"
                            value={kbDataList?.categoryName?.ar}
                         
                          />
                        </div>
                        <div className="mb-3">
                          <AvField
                            name="sku"
                            label="List of Product SKU*"
                            placeholder="Type Something"
                            type="textarea"
                            value={inputValue === ''? kbDataList?.sku : inputValue}
                            onChange={handleInputChange}
                            required
                          />
                        </div>
                        <h5>Rows</h5>
                        <div className="mb-3">
                     
                     <label>pages*</label>
              
                                  
                                    <Select
                                      value= {selectedRow}
                                      isMulti
                                      name="row"
                                      options={rowName}
                                      className="basic-multi-select"
                                      classNamePrefix="select"
                                      onChange={(value) =>
                                        handleRowsMultiSelection(value)
                                      }
                                    />
                                 
                              
                                    
                                      
                                   </div> 
                           
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col lg={4} md={4}>
              <Card className="statusDetail">
                <CardHeader className="card_header">
                  <label>Status</label>
                </CardHeader>
                <hr></hr>
                <CardBody>
                  <Row>
                    <Col>
                      <label>Current</label>
                    </Col>

                    <Col className="textAlignRight">
                      {" "}
                      <Badge className={` ${stsBtn} me-2`}>{toggleValue} </Badge>
                    </Col>
                  </Row>
                  <ButtonDropdown
                    isOpen={drp_secondary_lg}
                    toggle={() => setDrp_secondary_lg(!drp_secondary_lg)}
                    className="mt-2 mb-2 catbuttonDropdown"
                  >
                    <DropdownToggle
                      caret
                      color="success"
                      className="btn btn-success btn-lg "
                   
                    >
                      {pageLoad && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}{" "}
                      {toggleValue} &nbsp;
                      <i className="mdi mdi-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="dropdownmenu-secondary dropdown-menu-end"    onClick={() => {
                        buttonRef?.current?.submit();
                      }}>
                        {toggleValue === "Published"? (<><DropdownItem onClick={() => handleSelect(0)}>
                        Unpublished
                      </DropdownItem></>):(<> <DropdownItem onClick={() => handleSelect(1)}>
                        Published
                      </DropdownItem>
</>)}
                      

                     
                  

                      
                    </DropdownMenu>
                  </ButtonDropdown>
                  <Row className="mt-3">
                    <label>ASSIGNED ROWS</label>
            
                    <Col className="colWidth">
             
                    <DragDropContext onDragEnd={handleDrop}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              { console.log("itemList-----",itemRowList)}
              {itemRowList?.map((item, index) => (
             
                
                <Draggable key={item?._id} draggableId={item?._id} index={index}>
                  
                  {(provided) => (
                    <div
                      className="item-container"
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div>
                     <img src= {drapIcon} className="dragImage"/> 
                    
                     
                      <span>
                 {item?.title?.en}
                 </span>
                 </div>
              <div class="dragImage2"> <img src= {dropIcon}/></div>
                    </div>
                  )}
                </Draggable>
              ))}
                    {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

                    </Col>



          
                  </Row>

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Toaster position="top-right" reverseOrder={false} />
      </div>
    </>
  );
};

export default UpdateAPlusPages;