import { gql } from '@apollo/client';
export const GET_PAGES_DATA_LIST= gql`
query GET_PAGES_DATA_LIST(
  $input:pagesAllInput!
)
{
    getAllPagesList(input: $input)
{
    categoriesData {
        _id
      categoryName{
        en ar
      }
      sku
      status
      createdAt
      updatedAt
      createdBy
    }
    totalRecord
  }
    }`;